// アンケートの管理

<template lang="pug">
el-card.admin-surveys

  header
    .go-back(style='margin-bottom: 0.5rem')
      el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      @click='createSurvey'
    ) アンケートを作成

  ul.survey-list

    li(v-for='item in surveys')

      .caution(v-if='!item.isOpened')
        | (注)「公開しない」になっていると開始日になっても回答できません。

      el-row(type='flex', align='middle')

        el-col.text-center(:span='3')
          .inline-block.py-1.px-3.text-sm.rounded-full.font-bold.text-white(
            :class='item.state === "active" ? "bg-teal-500" : "bg-gray-400"'
          )
            | {{ item.state === 'active' ? '受付中' : ( item.state === 'after' ? '受付終了' : '受付前' ) }}
          .mt-1.text-sm 公開 : {{ item.isOpened ? 'する' : 'しない' }}

        el-col.second(:span='17')
          h3.text-xl.font-bold.text-teal-800 {{ item.title }}
          //- 対象者
          p.mt-1
            span.text-gray-500 対象者:
            span.ml-1 {{ item.userGroupName }}
          p.mt-1
            span.text-gray-500 受付期間:
            span.ml-1 {{ item.start | jpDate }} 〜 {{ item.end | jpDate }}
          //- 操作ボタン群
          .mt-2
            el-button.p-2(
              size='medium',
              icon='el-icon-star-off',
              @click='modifySurvey(item.id)'
            ) 基本情報
            el-button.p-2(
              size='medium',
              icon='el-icon-document',
              @click='modifyForm(item.id)'
            ) フォーム
            //- 不特定多数向けなら回答状況は表示しない
            el-button.p-2(
              v-if='!item.isForAll',
              size='medium',
              icon='el-icon-view',
              @click='showRecipientsDialog(item.id)'
            ) 回答状況
            el-button.p-2(
              size='medium',
              icon='el-icon-search',
              @click='$router.push({ name: "SurveyResult", params: { surveyId: item.id } })'
            ) 集計結果
            el-button.p-2(
              size='medium',
              icon='el-icon-copy-document',
              @click='copySurvey(item)'
            ) コピー
            el-button.p-2(
              v-if='!item.answeredNum',
              size='medium',
              icon='el-icon-delete',
              @click='removeSurvey(item)'
            ) 削除

        el-col.text-right(:span='4')
          .inline-block.p-2.bg-gray-200.rounded-lg.text-center.text-sm
            span.text-gray-500 回答済:
            span.ml-1 {{ item.answeredNum }}名
            //- 不特定多数向けなら対象者数を表示しない
            template(v-if='!item.isForAll')
              .my-1.border-t.border-gray-400
              span.text-gray-500 対象:
              span.ml-1 {{ item.targetNum }}名

  modify-survey-basis-dialog(
    :visible.sync='modifySurveyBasisDialogVisible',
    :survey-id='currentSurveyId',
    @ok='updatedSurvey'
  )

  survey-recipients-dialog(
    :visible.sync='surveyRecipientsDialogVisible',
    :survey-id='currentSurveyId'
  )
</template>

<script>
import ModifySurveyBasisDialog from '@/dialogs/modify-survey-basis-dialog'
import SurveyRecipientsDialog from '@/dialogs/survey-recipients-dialog'

import getSurveyListApi from '@/api/admin/get-survey-list'
import removeSurveyApi from '@/api/admin/remove-survey'
import copySurveyApi from '@/api/admin/copy-survey'

export default {
  name: 'AdminSurveys',

  components: {
    ModifySurveyBasisDialog,
    SurveyRecipientsDialog,
  },

  data() {
    return {
      surveys: [],
      currentSurveyId: null,
      modifySurveyBasisDialogVisible: false,
      surveyRecipientsDialogVisible: false,
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      const response = await getSurveyListApi()
      if (!response.ok) return
      this.surveys = response.payload.items
    },

    createSurvey() {
      this.currentSurveyId = null
      this.modifySurveyBasisDialogVisible = true
    },

    modifySurvey(surveyId) {
      this.currentSurveyId = surveyId
      this.modifySurveyBasisDialogVisible = true
    },

    // 保存・更新が完了した
    updatedSurvey() {
      this.$message({
        message: '基本情報を保存しました。',
        type: 'success',
      })
      this.init()
    },

    modifyForm(surveyId) {
      this.$router.push({
        name: 'SurveyForm',
        params: { surveyId },
      })
    },

    // 回答状況のダイアログを開く
    showRecipientsDialog(surveyId) {
      this.currentSurveyId = surveyId
      this.surveyRecipientsDialogVisible = true
    },

    // アンケートの削除
    async copySurvey(item) {
      try {
        await this.$confirm(`「${item.title}」をコピーしますか？`, '確認', {
          confirmButtonText: 'コピー',
          cancelButtonText: 'キャンセル',
        })
      } catch (e) {
        return
      }
      const response = await copySurveyApi(item.id)
      if (!response.ok) {
        this.$alert('アンケートのコピーに失敗しました。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: 'アンケートをコピーしました。',
          type: 'success',
        })
        this.init()
      }
    },

    // アンケートの削除
    async removeSurvey(item) {
      try {
        await this.$confirm(`「${item.title}」を削除してもよろしいですか？`, '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
      } catch (e) {
        return
      }
      const response = await removeSurveyApi(item.id)
      if (!response.ok) {
        this.$alert('アンケートの削除に失敗しました。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: 'アンケートを保存しました。',
          type: 'success',
        })
        this.init()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.admin-surveys

  ul.survey-list
    list-style-type: none
    margin-top: 1rem
    border-bottom: 1px solid #ccc

    li
      padding: 1rem
      border-top: 1px solid #ccc

      .caution
        font-size: 0.85rem
        background: #ffecef
        color: #da1c1c
        padding: 0.3rem
        border-radius: 10px
        text-align: center
        // for IE
        margin-bottom: 0.5rem
</style>
