// アンケートの編集ダイアログ

<template lang="pug">
el-dialog.modify-survey-basis-dialog(
  title='アンケートの基本情報',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)

  article

    el-form(:model='form', :rules='rules', ref='form')

      //- アンケートの対象者
      el-form-item(label='対象者', prop='userGroupId')
        //- 対象グループの選択
        //- 選択時に絞り込みしてあるユーザをクリア
        el-select(
          v-model='form.userGroupId',
          @change='form.recipients = null'
        )
          el-option(
            v-for='group in groupList',
            :key='group.id',
            :label='group.name',
            :value='group.id'
          )
        //- アプリ向けのユーザグループなら対象者選択は表示しない(不特定多数が対象なので)
        template(v-if='selectedUserGroup && !isSelectedUserGroupForApp')
          span &nbsp;のうちの&nbsp;
          el-button(@click='specifyUsers') {{ recipients }}
      //- 公開
      el-form-item(label='公開')
        el-switch(
          active-text='する',
          inactive-text='しない',
          v-model='form.isOpened'
        )

      //- 期間
      el-form-item(label='開始日', prop='start')
        el-date-picker(
          v-model='form.start',
          type='date'
        )
      el-form-item(label='終了日', prop='end')
        el-date-picker(
          :value='form.end',
          @input='updateEndDate'
          type='date'
        )

      //- タイトル
      el-form-item(label='タイトル')
        el-input(
          v-model='form.title'
        )

    footer
      el-button(
        type='primary',
        @click='save'
      ) 保存
      el-button(
        @click='close(false)'
      ) キャンセル

  user-select-dialog(
    :visible.sync='dialogVisible',
    :user-group-id='form.userGroupId',
    v-model='form.recipients'
  )
</template>

<script>
import getGroupListApi from '@/api/admin/get-group-list'
import createSurveyApi from '@/api/admin/create-survey'
import getSurveyApi from '@/api/admin/get-survey'
import updateSurveyApi from '@/api/admin/update-survey'

import UserSelectDialog from '@/dialogs/user-select-dialog'

export default {
  name: 'ModifySurveyBasisDialog',

  components: {
    UserSelectDialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // 更新の際に必要
    surveyId: {
      type: Number,
    },
  },

  data() {
    return {
      // フォームのデータ
      form: {
        // 対象のグループ
        userGroupId: null,
        // グループのうちの実際に対象とするユーザのId
        // nullならグループ全員
        recipients: null,
        // 公開するか
        isOpened: false,
        // 開始日
        start: null,
        // 終了日
        end: null,
        // タイトル
        title: '',
      },

      // バリデーションルール
      rules: {
        userGroupId: [{ required: true, message: '対象を選択してください。' }],
        start: [{ required: true, message: '開始日を選択してください。' }],
        end: [{ required: true, message: '終了日を選択してください。' }],
        title: [{ required: true, message: 'タイトルを入力してください。' }],
      },

      // 既存のグループのリスト
      groupList: [],

      // ユーザ選択ダイアログ表示フラグ
      dialogVisible: false,
    }
  },

  computed: {
    // アンケート対象者
    recipients() {
      return this.form.recipients ? `${this.form.recipients.length}名` : '全員'
    },
    // 選択中のユーザグループ
    selectedUserGroup() {
      if (!this.form.userGroupId) return undefined
      return this.groupList.find((item) => item.id === this.form.userGroupId)
    },
    // 選択中のグループがスマホアプリ用のものか
    isSelectedUserGroupForApp() {
      if (!this.selectedUserGroup) return false
      return this.selectedUserGroup.acceptableRole === 'applicant'
    },
  },

  methods: {
    async open() {
      if (this.$refs.form) this.$refs.form.resetFields()
      await this._initGroupList()
      if (this.surveyId) await this._initForModify()
      else this._initForCreate()
    },
    // 編集のための準備
    async _initForModify() {
      const response = await getSurveyApi(this.surveyId)
      if (!response.ok) return
      const detail = response.payload
      this.form.userGroupId = detail.userGroupId
      this.form.recipients = detail.recipients
      this.form.isOpened = detail.isOpened
      this.form.start = detail.start ? new Date(detail.start.replace(/-/g, '/')) : null
      this.form.end = detail.end ? new Date(detail.end.replace(/-/g, '/')) : null
      this.form.title = detail.title
    },
    // 新規作成時のイニシャライズ処理
    _initForCreate() {
      this.form.userGroupId = null
      this.form.recipients = null
      this.form.isOpened = false
      this.form.start = null
      this.form.end = null
      this.form.title = ''
    },

    // 期間の終わりは強制的に23:59:59に
    updateEndDate(date) {
      const clone = new Date(date.getTime())
      clone.setHours(23)
      clone.setMinutes(59)
      clone.setSeconds(59)
      this.form.end = clone
    },

    // ダイアログを閉じる
    close(success) {
      if (success) this.$emit('ok')
      // ダイアログを閉じる
      this.$emit('update:visible', false)
    },

    // グループのリストの取得
    async _initGroupList() {
      const response = await getGroupListApi()
      if (!response.ok) {
        return
      }
      this.groupList = response.payload.items
    },

    // グループのなかから対象ユーザを絞る
    specifyUsers() {
      this.dialogVisible = true
    },

    // 保存
    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      let response
      if (this.surveyId) {
        response = await updateSurveyApi(this.surveyId, this.form)
      } else {
        response = await createSurveyApi(this.form)
      }
      if (!response.ok) {
        this.$alert('基本情報の保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      // ダイアログを閉じる
      this.close(true)
    },
  },
}
</script>
