// アンケートのコピー

import api from '../api'

const path = '/admin/surveys/{id}/copy'

export default (surveyId) => {
  const url = path.replace('{id}', surveyId)
  return api({
    method: 'post',
    url,
    auth: true,
  })
}
