// アンケートの作成

import api from '../api'

const path = '/admin/surveys'

export default ({ userGroupId, recipients = null, isOpened = false, start, end, title }) => {
  return api({
    method: 'post',
    url: path,
    params: {
      userGroupId,
      recipients,
      isOpened,
      start,
      end,
      title,
    },
    auth: true,
  })
}
