// アンケートの基本情報の更新

import api from '../api'

const path = '/admin/surveys/{id}'

export default (surveyId, { userGroupId, recipients = null, isOpened, title, start, end }) => {
  const url = path.replace('{id}', surveyId)
  return api({
    method: 'put',
    url,
    params: {
      userGroupId,
      recipients,
      isOpened,
      title,
      start,
      end,
    },
    auth: true,
  })
}
