import { render, staticRenderFns } from "./survey-recipients-dialog.vue?vue&type=template&id=c2869ac8&scoped=true&lang=pug"
import script from "./survey-recipients-dialog.vue?vue&type=script&lang=js"
export * from "./survey-recipients-dialog.vue?vue&type=script&lang=js"
import style0 from "./survey-recipients-dialog.vue?vue&type=style&index=0&id=c2869ac8&prod&lang=sass"
import style1 from "./survey-recipients-dialog.vue?vue&type=style&index=1&id=c2869ac8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2869ac8",
  null
  
)

export default component.exports