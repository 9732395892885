// アンケート対象のリストと回答状況

<template lang="pug">
el-dialog.survey-recipients-dialog(
  title='回答状況',
  width='60%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)

  article(v-if='ready')

    p 回答数 {{ answeredNum }} / 対象数 {{ targetNum }}

    .for-org(v-if='userRole === "org" || userRole === "staff"')
      el-table(
        :data='recipients',
        height='500',
        :row-class-name='tableRowClassName'
      )
        el-table-column(
          label='番号',
          prop='organizationNumber',
          sortable
        )
        el-table-column(
          label='園名',
          prop='organizationName'
        )
        el-table-column(
          label='回答日時',
          prop='answeredAt',
          sortable
        )

    .for-student(v-else)
      el-table(
        :data='recipients',
        height='500',
        :row-class-name='tableRowClassName'
      )
        el-table-column(
          label='番号',
          prop='loginName',
          sortable
        )
        el-table-column(
          label='名前',
          prop='name'
        )
        el-table-column(
          label='園名',
          prop='organizationName'
        )
        el-table-column(
          label='回答日時',
          prop='answeredAt',
          sortable
        )

  footer
    el-button(
      @click='close()'
    ) 閉じる
    el-button(
      type='warning',
      @click='showMessageDialog'
    ) 未回答者にメッセージ送信

  modify-message-dialog(
    :visible.sync='dialogVisible',
    :initialUserGroupId='userGroupId',
    :initialRecipients='notAnsweredUserIds',
    @send-message='sendMessage'
  )
</template>

<script>
import get from 'lodash.get'

import getSurveyRecipientsApi from '@/api/admin/get-survey-recipients'

import ModifyMessageDialog from '@/dialogs/modify-message-dialog'

export default {
  name: 'SurveyRecipientsDialog',

  components: {
    ModifyMessageDialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
    },
  },

  data() {
    return {
      ready: false,
      recipients: [],
      dialogVisible: false,
    }
  },

  computed: {
    userRole() {
      return get(this.recipients, '[0].roleId', null)
    },
    answeredNum() {
      return this.recipients.filter((item) => item.answeredAt).length
    },
    targetNum() {
      return this.recipients.length
    },
    userGroupId() {
      return get(this.recipients, '[0].userGroupId', null)
    },
    notAnsweredUserIds() {
      return this.recipients.filter((item) => !item.answeredAt).map((item) => item.userId)
    },
  },

  methods: {
    async open() {
      this.ready = false
      this.recipients = []
      if (!this.surveyId) return
      const response = await getSurveyRecipientsApi(this.surveyId)
      if (!response.ok) return
      this.recipients = response.payload.items
      this.ready = true
    },

    close() {
      this.$emit('update:visible', false)
    },

    tableRowClassName({ row }) {
      return !row.answeredAt ? 'not-answered' : ''
    },

    showMessageDialog() {
      this.dialogVisible = true
    },

    // 送信完了
    sendMessage() {
      this.$alert('メッセージを送信しました。', '送信完了', {
        type: 'success',
      })
    },
  },
}
</script>

<style lang="sass">
.survey-recipients-dialog
  .el-table
    .not-answered
      background: #ffe1e1
</style>

<style lang="sass" scoped>
.survey-recipients-dialog
  article
    margin-top: -20px
  footer
    margin-top: 2rem
</style>
