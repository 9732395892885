// アンケートの削除

import api from '../api'

const path = '/admin/surveys/{id}'

export default (surveyId) => {
  const url = path.replace('{id}', surveyId)
  return api({
    url,
    method: 'delete',
    auth: true,
  })
}
