// 管理者向けのアンケートの取得

import api from '../api'

const path = '/admin/surveys'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
